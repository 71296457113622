/* eslint-disable react/jsx-fragments */
import React from 'react';

import { Global, css } from '@emotion/react';
import IntersectionObserverPolyfill from 'components/IntersectionObserverPolyfill';
import PageTransition from 'components/PageTransition';
import ScrollListener from 'components/ScrollListener';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import AppProvider from 'state/AppState';
import globalStyles from 'styles/globalStyles';
import './reset.css';

// eslint-disable-next-line react/prop-types
const Layout = ({ children, location }) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={(/* data */) => (
            <IntersectionObserverPolyfill>
                <AppProvider>
                    <ScrollListener>
                        <Global
                            styles={css`${globalStyles}`}
                        />
                        <PageTransition location={location}>
                            {children}
                        </PageTransition>
                    </ScrollListener>
                </AppProvider>
            </IntersectionObserverPolyfill>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
