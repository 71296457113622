/* eslint-disable import/order */
// Basis Grotesque

import BasisGrotesqueBoldWoff from '../assets/fonts/basis-grotesque/BasisGrotesque-Bold.woff';
import BasisGrotesqueBoldWoff2 from '../assets/fonts/basis-grotesque/BasisGrotesque-Bold.woff2';
import BasisGrotesqueLightWoff from '../assets/fonts/basis-grotesque/BasisGrotesque-Light.woff';
import BasisGrotesqueLightWoff2 from '../assets/fonts/basis-grotesque/BasisGrotesque-Light.woff2';
import BasisGrotesqueMediumWoff from '../assets/fonts/basis-grotesque/BasisGrotesque-Medium.woff';
import BasisGrotesqueMediumWoff2 from '../assets/fonts/basis-grotesque/BasisGrotesque-Medium.woff2';
import BasisGrotesqueWoff from '../assets/fonts/basis-grotesque/BasisGrotesque-Regular.woff';
import BasisGrotesqueWoff2 from '../assets/fonts/basis-grotesque/BasisGrotesque-Regular.woff2';
import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff';
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2';

// mackinac

import MackinacBookWoff from '../assets/fonts/mackinac/P22MackinacPro-Book.woff';
import MackinacBookWoff2 from '../assets/fonts/mackinac/P22MackinacPro-Book.woff2';
import MackinacBookItalicWoff from '../assets/fonts/mackinac/P22MackinacPro-BookItalic.woff';
import MackinacBookItalicWoff2 from '../assets/fonts/mackinac/P22MackinacPro-BookItalic.woff2';

// FT Regola Neue

import FTRegolaNeueBoldWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Bold.woff';
import FTRegolaNeueLightWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Light.woff';
import FTRegolaNeueMediumWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Medium.woff';
import FTRegolaNeueRegularWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Regular.woff';
import FTRegolaNeueSemiboldWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Semibold.woff';
import FTRegolaNeueHeavyWoff from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Heavy.woff';
import FTRegolaNeueBoldWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Bold.woff2';
import FTRegolaNeueLightWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Light.woff2';
import FTRegolaNeueMediumWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Medium.woff2';
import FTRegolaNeueRegularWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Regular.woff2';
import FTRegolaNeueSemiboldWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Semibold.woff2';
import FTRegolaNeueHeavyWoff2 from '../assets/fonts/ft-regola-neue/FTRegolaNeue-Heavy.woff2';

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
  @font-face {
    font-family: '${fontName}';
    src:  url('${woff}') format('woff'),
          url('${woff2}') format('woff2');
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
    font-display: swap;
  }
`;
export const MaterialIcons = 'Material Icons';
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2);

export const BasisGrotesque = 'Basis Grotesque';
export const BasisGrotesqueFont = fontFace(BasisGrotesque, BasisGrotesqueWoff, BasisGrotesqueWoff2);
export const BasisGrotesqueLightFont = fontFace(BasisGrotesque, BasisGrotesqueLightWoff, BasisGrotesqueLightWoff2, '300');
export const BasisGrotesqueMediumFont = fontFace(BasisGrotesque, BasisGrotesqueMediumWoff, BasisGrotesqueMediumWoff2, '500');
export const BasisGrotesqueBoldFont = fontFace(BasisGrotesque, BasisGrotesqueBoldWoff, BasisGrotesqueBoldWoff2, '700');

export const Mackinac = 'Mackinac';
export const MackinacFont = fontFace(Mackinac, MackinacBookWoff, MackinacBookWoff2);
export const MackinacFontItalic = fontFace(Mackinac, MackinacBookItalicWoff, MackinacBookItalicWoff2, 'normal', 'italic');

export const FTRegolaNeue = 'FT Regola Neue';
export const FTRegolaNeueFont = fontFace(FTRegolaNeue, FTRegolaNeueRegularWoff, FTRegolaNeueRegularWoff2);
export const FTRegolaNeueLightFont = fontFace(FTRegolaNeue, FTRegolaNeueLightWoff, FTRegolaNeueLightWoff2, '300');
export const FTRegolaNeueMediumFont = fontFace(FTRegolaNeue, FTRegolaNeueMediumWoff, FTRegolaNeueMediumWoff2, '500');
export const FTRegolaNeueSemiboldFont = fontFace(FTRegolaNeue, FTRegolaNeueSemiboldWoff, FTRegolaNeueSemiboldWoff2, '600');
export const FTRegolaNeueBoldFont = fontFace(FTRegolaNeue, FTRegolaNeueBoldWoff, FTRegolaNeueBoldWoff2, '700');
export const FTRegolaNeueHeavyFont = fontFace(FTRegolaNeue, FTRegolaNeueHeavyWoff, FTRegolaNeueHeavyWoff2, '800');
