import { rgba } from 'polished';

import * as colors from './colors';
import { responsiveStyles } from './util';

import './fonts';

// Place global Typography in this file
export const primaryFont = 'FT Regola Neue, -apple-system, serif';
export const secondaryFont = 'Mackinac, -apple-system, serif';
export const medium = 500;
export const bold = 700;

export const bodyLarge = `
${responsiveStyles('font-size', 18, 16, 16, 16)}
${responsiveStyles('line-height', 28, 24, 24, 24)}
font-family: ${primaryFont};
font-style: 400;
font-weight: normal;
`;

export const bodyMedium = `
${responsiveStyles('font-size', 16, 16, 12, 12)}
font-family: ${primaryFont};
font-style: 400;
font-weight: normal;
line-height: 24px;
`;

export const body = `
  ${responsiveStyles('font-size', 16, 16, 16, 16)}
  ${responsiveStyles('line-height', 24, 24, 24, 24)}
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
`;
export const bodySmall = `
${responsiveStyles('font-size', 14, 12, 10, 10)}
font-family: ${primaryFont};
font-style: 400;
font-weight: normal;
line-height: 20px;
`;

export const bodyGrey = `
  ${body}
  color: ${colors.grey4};
`;

export const display = `
${responsiveStyles('font-size', 96, 96, 48, 48)}
  font-family: ${primaryFont};
  line-height: 104px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2%;
`;

export const displayAlt = `
${responsiveStyles('font-size', 64, 64, 28, 28)}
  font-family: ${primaryFont};
  line-height: 72px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2%;
`;

export const h1 = `
${responsiveStyles('font-size', 52, 52, 40, 40)}
${responsiveStyles('line-height', 64, 64, 48, 48)}
  font-family: ${primaryFont};
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;
  `;

export const h1Primary = `
  ${responsiveStyles('font-size', 96, 96, 36, 36)}
  ${responsiveStyles('line-height', 116, 116, 44, 44)}
  font-family: ${primaryFont};
  letter-spacing: -0.01em;
  font-weight: ${medium};
  font-style: normal;
  letter-spacing: -1%;
  `;

export const h2 = `
${responsiveStyles('font-size', 40, 40, 28, 28)}
${responsiveStyles('line-height', 48, 48, 32, 32)}
  font-family: ${primaryFont};
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;
  `;

export const h2Primary = `
  ${responsiveStyles('font-size', 64, 64, 36, 36)}
  ${responsiveStyles('line-height', 72, 72, 44, 44)}
  font-family: ${primaryFont};
  letter-spacing: -0.01em;
  font-weight: ${medium};
  font-style: normal;
  letter-spacing: -1%;
  `;

export const h3 = `
${responsiveStyles('font-size', 32, 32, 28, 28)}
  font-family: ${primaryFont};
  line-height: 40px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;
`;

export const h4 = `
${responsiveStyles('font-size', 28, 28, 24, 24)}
${responsiveStyles('line-height', 40, 40, 36, 36)}
  font-family: ${primaryFont};
  line-height: 40px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5%;
`;

export const h5 = `
${responsiveStyles('font-size', 24, 24, 20, 20)}
  font-family: ${primaryFont};
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5%;
`;

// Basis Grotesque Pro Regular, 24 = h6
export const h6 /* ACCENT */ = `
${responsiveStyles('font-size', 18, 16, 14, 14)}
  font-family: ${primaryFont};
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
`;

export const navigation = `
${responsiveStyles('font-size', 16, 16, 16, 14)}
font-family: ${primaryFont};
font-style: normal;
font-weight: normal;
line-height: 150%;
letter-spacing: 0.02em;
`;

export const infographic = `
  ${responsiveStyles('font-size', 32, 24, 18, 18)}
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
`;

export const blockquote = `
  ${bodyLarge}
  font-style: normal;
`;

export const eyebrow = `
${responsiveStyles('font-size', 18, 16, 14, 14)}
  color: ${colors.periwinkle};
  font-family: ${primaryFont};
  font-style: 500;
  font-weight: normal;
  line-height: 24px;
`;

export const buttonStyle = `
font-family: ${primaryFont};
font-weight: 400;
`;
export const buttonStyleLarge = `
  ${h5}
  ${responsiveStyles('font-size', 28, 26, 24, 24)}
  font-family: ${primaryFont};
  font-weight: ${medium};
`;

export const code = `
  color: ${colors.periwinkle};
`;

export const storyNotes = `
  max-width: 750px;
  p {
    code {
      background: ${rgba(colors.textColor, 0.1)};
      color: ${colors.textColor};
      border-radius: 3px;
      padding: .05em .35em .15em;
      font-style: normal;
    }
  }
`;
