import { rgba } from 'polished';

import * as colors from './colors';
import { responsiveStyles } from './util';

import './fonts';

// Place global Typography in this file
export const primaryFont = 'FT Regola Neue, -apple-system, serif';
export const secondaryFont = 'Mackinac, -apple-system, serif';
export const medium = 500;
export const bold = 700;

export const display = `
  ${responsiveStyles('font-size', 100, 100, 52, 52)}
  font-family: ${primaryFont};
  line-height: 100%;
  font-weight: normal;
  font-style: normal;
`;

export const displayAlt = `
  ${responsiveStyles('font-size', 52, 52, 40, 40)}
  ${responsiveStyles('line-height', 58, 58, 44, 44)}

  font-family: ${primaryFont};
  line-height: 100%;
  font-weight: normal;
  font-style: normal;
`;

export const navigation = `
  ${responsiveStyles('font-size', 16, 16, 16, 14)}
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0.02em;
`;

export const infographic = `
  ${responsiveStyles('font-size', 32, 24, 18, 18)}
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
`;

export const code = `
  color: ${colors.periwinkle};
`;

export const storyNotes = `
  max-width: 750px;
  p {
    code {
      background: ${rgba(colors.textColor, 0.1)};
      color: ${colors.textColor};
      border-radius: 3px;
      padding: .05em .35em .15em;
      font-style: normal;
    }
  }
`;

export const display1 = `
${responsiveStyles('font-size', 96, 96, 64, 64)}
${responsiveStyles('line-height', 104, 104, 72, 72)}
  font-family: ${primaryFont};
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2%;`;

export const display2 = `
${responsiveStyles('font-size', 64, 64, 52, 52)}
${responsiveStyles('line-height', 72, 72, 64, 64)}
  font-family: ${primaryFont};
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2%;`;

export const h1 = `
${responsiveStyles('font-size', 52, 52, 40, 40)}
${responsiveStyles('line-height', 64, 64, 48, 48)}
  font-family: ${primaryFont};
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;`;

export const h2 = `
${responsiveStyles('font-size', 40, 40, 32, 32)}
${responsiveStyles('line-height', 48, 48, 40, 40)}
  font-family: ${primaryFont};
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;`;

export const h3 = `
${responsiveStyles('font-size', 32, 32, 28, 28)}
  font-family: ${primaryFont};
  line-height: 40px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1%;`;

export const h4 = `
${responsiveStyles('font-size', 28, 28, 24, 24)}
${responsiveStyles('line-height', 40, 40, 36, 36)}
  font-family: ${primaryFont};
  line-height: 40px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5%;`;

export const h5 = `
${responsiveStyles('font-size', 24, 24, 18, 18)}
${responsiveStyles('line-height', 36, 36, 24, 24)}
  font-family: ${primaryFont};
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5%;`;

export const h6 = `
${responsiveStyles('font-size', 18, 16, 14, 14)}
  font-family: ${primaryFont};
  line-height: 24px;
  font-weight: 500;
  font-style: normal;`;

export const bodyLg = `
  ${responsiveStyles('font-size', 18, 16, 16, 16)}
  ${responsiveStyles('line-height', 28, 24, 24, 24)}
  font-family: ${primaryFont};
  font-style: 400;
  font-weight: normal;
`;

export const bodyMd = `
  ${responsiveStyles('font-size', 16, 16, 12, 12)}
  font-family: ${primaryFont};
  font-style: 400;
  font-weight: normal;
  line-height: 24px;
`;

export const bodySm = `
  ${responsiveStyles('font-size', 14, 12, 10, 10)}
  font-family: ${primaryFont};
  font-style: 400;
  font-weight: normal;
  line-height: 20px;
`;

export const caption = `
  ${responsiveStyles('font-size', 12, 12, 10, 10)}
  font-family: ${primaryFont};
  font-style: 400;
  font-weight: normal;
  line-height: 16px;
`;

export const eyebrow = `
${responsiveStyles('font-size', 18, 16, 14, 14)}
  color: ${colors.periwinkle};
  font-family: ${primaryFont};
  font-style: 500;
  font-weight: normal;
  line-height: 24px;
`;

export const labelMd = `
  ${responsiveStyles('font-size', 16, 16, 14, 14)}
  font-family: ${primaryFont};
  font-style: 400;
  font-weight: normal;
  line-height: 20px;
`;

export const blockquote = `
  ${bodyLg}
  font-style: normal;
`;

export const buttonStyle = `
  font-family: ${primaryFont};
  font-weight: 400;
`;

export const buttonStyleLarge = `
  ${labelMd}
  font-family: ${primaryFont};
  font-weight: 400;
`;
