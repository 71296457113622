import * as mq from 'styles/mediaQueries';
import { themes, themesV2, themesV3 } from 'styles/themes';

export const linearInterpolation = (maxInput, minInput, maxWidth, minWidth) => {
    const slope = (maxInput - minInput) / (maxWidth - minWidth);
    let yInterceptor = minInput - slope * minWidth;
    let yInterceptorPosition = true;
    if (yInterceptor < 0) {
        yInterceptor *= -1;
        yInterceptorPosition = false;
    }
    return `calc(${slope * 100}vw ${!yInterceptorPosition ? '-' : '+'} ${yInterceptor.toFixed(2)}px)`;
};

export const responsiveStyles = (styleType, large, medium, small, tiny) => `
  ${mq.largerAndUp} {
    ${styleType}: ${linearInterpolation(medium, large, mq.largerBreakpoint + 1, mq.extraExtraLargeBreakpoint)};
  }
  ${mq.largerAndBelow} {
    ${styleType}: ${linearInterpolation(small, medium, mq.mediumBreakpoint + 1, mq.largerBreakpoint)};
  }
  ${mq.mediumAndBelow} {
    ${styleType}: ${linearInterpolation(tiny, small, mq.tinyBreakpoint, mq.mediumBreakpoint)};
  }
`;

export const setBackgroundColor = (designSystem, setTheme = 'default') => {
    if (designSystem === 'v2') {
        return themesV2[setTheme].background;
    } if (designSystem === 'v3') {
        return themesV3[setTheme].background;
    }
    return themes[setTheme].background;
};

export const fontSmoothing = `
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
`;
