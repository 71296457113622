import { rgba, darken, lighten } from 'polished';

import * as colors from './colors';

// Themes (ThemeSelector Component)
export const themes = {
    default: { // same as white
        color: colors.black,
        background: colors.white,
        hoverColor: colors.periwinkle,
        anchorColor: colors.periwinkle,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    white: {
        color: colors.black,
        background: colors.white,
        hoverColor: colors.periwinkle,
        anchorColor: colors.periwinkle,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    beige: {
        color: colors.black,
        background: colors.beige,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    black: {
        color: colors.white,
        background: colors.black,
        hoverColor: colors.white,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'white',
    },
    green: {
        color: colors.white,
        background: colors.olive,
        hoverColor: colors.white,
        buttonTheme: 'white',
        buttonThemeSecondary: 'whiteTransparentGreenBackground',
        buttonThemeTertiary: 'whiteTransparentGreenBackground',
        buttonThemeMobile: 'white',
    },
    orange: {
        color: colors.white,
        background: colors.rust,
        hoverColor: colors.white,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'white',
    },
    blue: {
        color: colors.white,
        background: colors.periwinkle,
        hoverColor: colors.white,
        buttonTheme: 'white',
        buttonThemeSecondary: 'whiteTransparentBlueBackground',
        buttonThemeTertiary: 'whiteTransparentBlueBackground',
        buttonThemeMobile: 'white',
    },
    grey1: {
        color: colors.black,
        background: colors.kioskGrey,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    lightGrey: {
        color: colors.black,
        background: colors.grey1,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blackTransparent',
        buttonThemeMobile: 'black',
    },
    mainColor: {
        color: colors.bgColor,
        background: colors.mainColor,
        hoverColor: colors.bgColor,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blackTransparent',
        buttonThemeMobile: 'white',
    },
    textColor: {
        color: colors.bgColor,
        background: colors.textColor,
        hoverColor: colors.bgColor,
        buttonTheme: 'default',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blackTransparent',
        buttonThemeMobile: 'default',
    },
    transparent: { // same as white
        color: colors.black,
        hoverColor: colors.black,
        background: 'transparent',
    }
};

// Themes - Design System v2 (ThemeSelector Component)
export const themesV2 = {
    default: { // same as white
        color: colors.black,
        background: colors.kioskGrey,
        hoverColor: colors.periwinkle,
        anchorColor: colors.periwinkle,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    white: {
        color: colors.black,
        background: colors.white,
        hoverColor: colors.periwinkle,
        anchorColor: colors.periwinkle,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    beige: {
        color: colors.black,
        background: colors.beige,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    black: {
        color: colors.kioskGrey,
        background: colors.black,
        hoverColor: colors.kioskGrey,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'white',
    },
    green: {
        color: colors.kioskGrey,
        background: colors.olive,
        hoverColor: colors.kioskGrey,
        buttonTheme: 'white',
        buttonThemeSecondary: 'whiteTransparentGreenBackground',
        buttonThemeTertiary: 'whiteTransparentGreenBackground',
        buttonThemeMobile: 'white',
    },
    orange: {
        color: colors.kioskGrey,
        background: colors.rust,
        hoverColor: colors.kioskGrey,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'white',
    },
    blue: {
        color: colors.kioskGrey,
        background: colors.periwinkle,
        hoverColor: colors.kioskGrey,
        buttonTheme: 'white',
        buttonThemeSecondary: 'whiteTransparentBlueBackground',
        buttonThemeTertiary: 'whiteTransparentBlueBackground',
        buttonThemeMobile: 'white',
    },
    grey1: {
        color: colors.black,
        background: colors.kioskGrey,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    lightGrey: {
        color: colors.black,
        background: colors.grey1,
        hoverColor: colors.black,
        buttonTheme: 'black',
        buttonThemeSecondary: 'blueTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    mainColor: {
        color: colors.bgColor,
        background: colors.mainColor,
        hoverColor: colors.bgColor,
        buttonTheme: 'white',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blackTransparent',
        buttonThemeMobile: 'white',
    },
    textColor: {
        color: colors.bgColor,
        background: colors.textColor,
        hoverColor: colors.bgColor,
        buttonTheme: 'default',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blackTransparent',
        buttonThemeMobile: 'default',
    },
    transparent: { // same as white
        color: colors.black,
        hoverColor: colors.black,
        background: 'transparent',
    }
};

export const themesV3 = {
    default: { // same as white
        color: colors.black,
        background: colors.kioskGrey,
        hoverColor: colors.periwinkle,
        anchorColor: colors.periwinkle,
        buttonTheme: 'periwinkle',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'black',
    },
    beige: {
        color: colors.black,
        background: colors.bone,
        hoverColor: colors.black,
        buttonTheme: 'periwinkle',
        buttonThemeSecondary: 'blackTransparent',
        buttonThemeTertiary: 'blueTransparent',
        buttonThemeMobile: 'periwinkle',
    },
};

// Button Themes
export const buttonThemes = {
    default: { // same as black
        color: colors.white,
        background: colors.black,
        borderColor: colors.black,
        hoverColor: colors.white,
        hoverBorderColor: colors.grey6,
        hoverBackground: colors.grey6
    },
    black: {
        color: colors.white,
        background: colors.black,
        borderColor: colors.black,
        hoverColor: colors.white,
        hoverBorderColor: colors.grey6,
        hoverBackground: colors.grey6
    },
    white: {
        color: colors.white,
        background: rgba(colors.kioskGrey, 0.20),
        borderColor: 'transparent',
        hoverColor: colors.white,
        hoverBorderColor: 'transparent',
        hoverBackground: rgba(colors.kioskGrey, 0.30)
    },
    periwinkle: {
        color: colors.white,
        background: colors.periwinkle,
        borderColor: 'transparent',
        hoverColor: colors.white,
        hoverBorderColor: 'transparent',
        hoverBackground: lighten(0.04, colors.periwinkle),
    },
    grey: {
        color: colors.black,
        background: colors.grey1,
        borderColor: 'transparent',
        hoverColor: colors.black,
        hoverBorderColor: 'transparent',
        hoverBackground: darken(0.05, colors.grey1)
    },
    lightPeriwinkleNoHover: {
        color: colors.black,
        background: colors.periwinkleLighten,
        borderColor: 'transparent'
    },
    blackTransparent: {
        color: colors.black,
        borderColor: colors.grey4,
        background: colors.transparent,
        hoverColor: colors.black,
        hoverBorderColor: colors.grey4,
        hoverBackground: rgba(colors.black, 0.04)
    },
    whiteTransparentBlueBackground: {
        color: colors.white,
        borderColor: colors.white,
        background: 'transparent',
        hoverColor: colors.periwinkle,
        hoverBorderColor: colors.white,
        hoverBackground: colors.white
    },
    whiteTransparentGreenBackground: {
        color: colors.white,
        borderColor: colors.white,
        background: 'transparent',
        hoverColor: colors.olive,
        hoverBorderColor: colors.white,
        hoverBackground: colors.white
    },
    blueTransparent: {
        color: colors.periwinkle,
        borderColor: colors.periwinkle,
        background: 'transparent',
        hoverColor: colors.white,
        hoverBorderColor: colors.periwinkle,
        hoverBackground: colors.periwinkle
    },
    currentColorTransparent: {
        color: 'currentcolor',
        borderColor: 'transparent',
        background: 'transparent',
        hoverColor: 'currentcolor',
        hoverBorderColor: 'transparent',
        hoverBackground: 'transparent'
    },
    blackTextToBlackHover: {
        color: colors.black,
        borderColor: 'transparent',
        background: 'transparent',
        hoverColor: colors.black,
        hoverBorderColor: 'transparent',
        hoverBackground: colors.transparent
    },
    greyWithoutBackground: {
        color: colors.grey6,
        background: 'transparent',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        hoverBackground: 'transparent',
    },
};

// (v2) Button Themes
export const buttonThemesV2 = {
    default: {
        color: colors.white,
        background: colors.black,
        borderColor: colors.black,
        hoverColor: colors.white,
        hoverBorderColor: colors.grey6,
        hoverBackground: colors.grey6
    },
    black: {
        color: colors.white,
        background: colors.black,
        borderColor: colors.black,
        hoverColor: colors.white,
        hoverBorderColor: colors.grey6,
        hoverBackground: colors.grey6
    },
    white: {
        color: colors.kioskGrey,
        borderColor: 'transparent',
        background: rgba(colors.kioskGrey, 0.25),
        hoverColor: colors.kioskGrey,
        hoverBorderColor: 'transparent',
        hoverBackground: rgba(colors.kioskGrey, 0.35),
    },
    blackTransparent: {
        color: colors.grey5,
        borderColor: colors.grey4,
        background: colors.transparent,
        hoverColor: colors.black,
        hoverBorderColor: colors.grey4,
        hoverBackground: rgba(colors.black, 0.025)
    },
    blueTransparent: {
        color: colors.kioskGrey,
        borderColor: 'transparent',
        background: rgba(colors.kioskGrey, 0.25),
        hoverColor: colors.kioskGrey,
        hoverBorderColor: 'transparent',
        hoverBackground: rgba(colors.kioskGrey, 0.35),
    },
    periwinkle: {
        color: colors.kioskGrey,
        background: colors.periwinkle,
        borderColor: 'transparent',
        hoverColor: colors.kioskGrey,
        hoverBorderColor: 'transparent',
        hoverBackground: darken(0.05, colors.periwinkle)
    },
    lightPeriwinkleNoHover: {
        color: colors.black,
        background: colors.periwinkleLighten,
        borderColor: 'transparent'
    },
    whiteTransparentBlueBackground: {
        color: colors.kioskGrey,
        borderColor: colors.kioskGrey,
        background: 'transparent',
        hoverColor: colors.periwinkle,
        hoverBorderColor: colors.kioskGrey,
        hoverBackground: colors.kioskGrey
    },
    whiteTransparentGreenBackground: {
        color: colors.kioskGrey,
        borderColor: colors.kioskGrey,
        background: 'transparent',
        hoverColor: colors.olive,
        hoverBorderColor: colors.kioskGrey,
        hoverBackground: colors.kioskGrey
    },
    currentColorTransparent: {
        color: 'currentcolor',
        borderColor: 'transparent',
        background: 'transparent',
        hoverColor: 'currentcolor',
        hoverBorderColor: 'transparent',
        hoverBackground: rgba(colors.grey2, 0.3)
    },
    blackTextToBlackHover: {
        color: colors.black,
        borderColor: 'transparent',
        background: 'transparent',
        hoverColor: colors.kioskGrey,
        hoverBorderColor: 'transparent',
        hoverBackground: colors.black
    }
};

export const buttonThemesV3 = {
    default: {
        color: colors.white,
        background: colors.black,
        borderColor: colors.black,
        hoverColor: colors.white,
        hoverBorderColor: colors.grey6,
        hoverBackground: colors.grey6
    },
    periwinkle: {
        color: colors.kioskGrey,
        background: colors.periwinkle,
        borderColor: 'transparent',
        hoverColor: colors.kioskGrey,
        hoverBorderColor: 'transparent',
        hoverBackground: darken(0.05, colors.periwinkle)
    },
};

// Input Themes
export const inputThemes = {
    default: {
        color: colors.textColor,
        placeholderColor: colors.lightTextColor,
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.textColor,
        hoverBorderColor: colors.textColor,
        focusBorderColor: colors.mainColor,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    beige: {
        color: colors.textColor,
        placeholderColor: colors.lightTextColor,
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.textColor,
        hoverBorderColor: colors.textColor,
        focusBorderColor: colors.mainColor,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    black: {
        color: colors.white,
        placeholderColor: rgba(colors.kioskGrey, 0.15),
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.kioskGrey,
        hoverBorderColor: colors.white,
        focusBorderColor: colors.white,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    green: {
        color: colors.white,
        placeholderColor: rgba(colors.kioskGrey, 0.15),
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.kioskGrey,
        hoverBorderColor: colors.white,
        focusBorderColor: colors.white,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    blue: {
        color: colors.white,
        placeholderColor: rgba(colors.kioskGrey, 0.15),
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.kioskGrey,
        hoverBorderColor: colors.white,
        focusBorderColor: colors.white,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    grey1: {
        color: colors.textColor,
        placeholderColor: colors.lightTextColor,
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.lightTextColor,
        hoverBorderColor: colors.textColor,
        focusBorderColor: colors.mainColor,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
    lightGrey: {
        color: colors.textColor,
        placeholderColor: colors.lightTextColor,
        background: colors.transparent,
        accentColor: colors.bgColor,
        hoverColor: colors.bgColor,
        borderColor: colors.lightTextColor,
        hoverBorderColor: colors.textColor,
        focusBorderColor: colors.mainColor,
        hoverBackground: colors.transparent,
        focusBackground: colors.transparent,
    },
};

export default themes;
