import { responsiveStyles } from './util';
import './fonts';

// Place global Typography in this file
export const primaryFont = 'FT Regola Neue, -apple-system, serif';
export const secondaryFont = 'Mackinac, -apple-system, serif';
export const medium = 500;
export const bold = 700;

export const display1 = `
    font-family: ${primaryFont};
    ${responsiveStyles('font-size', 96, 96, 60, 60)};
    ${responsiveStyles('line-height', 96, 96, 60, 60)};
    ${responsiveStyles('letter-spacing', -2.88, -2.88, -1.2, -1.2)};
    font-weight: 900;
    margin:0;
`;

export const display2 = `
    font-family: ${primaryFont};
    font-weight: 900;
    font-size: 52px;
    line-height: 52px;
    letter-spacing: -1.56px;
    margin:0;
`;

export const eyebrow = `
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-family: ${primaryFont};
    font-style: normal;
    margin: 0;
`;

export const h4 = `
    font-size: 18px;
    line-height:24px
    font-weight: 500;
    font-family: ${primaryFont};
    font-style: normal;
    margin:0;
`;

export const h3 = `
    font-size: 28px;
    font-weight: 600px;
    line-height:33.6px;
    letter-spacing:-0.5%;
    font-family: ${primaryFont};
    margin:0;
`;

export const tableHead = `
    font-weight: 500px;
    font-size: 16px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin:0
`;

export const tableMainField = `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: ${primaryFont};
    margin:0
`;

export const tableData = `
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-family: ${primaryFont};
    margin:0
`;

export const bodyMd = `
    ${responsiveStyles('font-size', 18, 18, 16, 16)};
    ${responsiveStyles('line-height', 24, 24, 20, 20)};
    font-family: ${primaryFont};
    font-weight: 400;
    margin:0
`;

export const bodySm = `
    ${responsiveStyles('font-size', 14, 14, 12, 12)};
    line-height: 128.571%;
    font-family: ${primaryFont};
    font-weight: 400;
`;

export const heading = `
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    font-family: ${primaryFont};
`;

export const clinicHeadline = `
    ${responsiveStyles('font-size', 52, 52, 40, 40)};
    font-weight: 600;
    font-family: ${primaryFont};
    font-style: normal;
`;

export const pillText = `
    font-family: ${primaryFont};
    ${responsiveStyles('font-size', 18, 18, 16, 16)};
    ${responsiveStyles('line-height', 24, 24, 20, 20)};
    font-style: normal;
    font-weight: 500;
`;
