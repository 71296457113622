/* eslint-disable */

const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // if (typeof window.IntersectionObserver === 'undefined') {
  //   import('intersection-observer');
  // }


  window.__DEV__ = true
};




const transitionDelay = 500

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}


export {
  onClientEntry,
  shouldUpdateScroll
}
