import * as util from './util';

export const verticalMargins = `
  ${util.responsiveStyles('margin-top', 100, 90, 70, 40)}
  ${util.responsiveStyles('margin-bottom', 100, 90, 70, 40)}
`;

export const verticalSpacing = (attr = 'padding-top', multiple = 1) => `
  ${util.responsiveStyles(attr, (120 * multiple), (90 * multiple), (70 * multiple), (40 * multiple))}
`;

export const baseBorderRadius = 12;
export const v2BorderRadius = 12;

export const maxWidth = 1440;

// Buttons, Inputs, Selects, etc.
export const uiElementSizes = {
    tiny: 32,
    small: 40,
    medium: 48,
    large: 48
};

export const borderRadius = `
  ${util.responsiveStyles('border-radius', 52, 52, 30, 24)}
`;
