/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useLayoutEffect } from 'react';

import AppContext from './AppContext';
// eslint-disable-next-line react/prop-types

export const TYPES = {
    NEWSLETTER: 'NEWSLETTER',
    WAITLIST: 'WAITLIST',
};

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children }) => {
    const initialState = {
        navigationOpen: false,
        popupOpen: false,
        popupId: null,
        popupData: null,
        pageTheme: 'default',
        openNavigation: () => { },
        closeNavigation: () => { },
        toggleNavigation: () => { },
        setPopupDate: () => { },
        setPageTheme: () => { },
        togglePopup: () => { },
    };

    const [state, setState] = useState(initialState);
    const { popupOpen, popupData } = state;

    useEffect(() => {
        function onKeyUp(e) {
            if (e.key === 'Escape') {
                setState({
                    ...state,
                    navigationOpen: false,
                    popupOpen: false,
                    popupId: null,
                    popupData: null,
                });
            }
        }
        window.addEventListener('keyup', onKeyUp);
        return () => window.removeEventListener('keyup', onKeyUp);
    });

    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        if (popupOpen || popupData) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = originalStyle;
        }
        // eslint-disable-next-line no-return-assign
        return () => (document.body.style.overflow = originalStyle);
    }, [popupOpen, popupData]);

    const openNavigation = () => setState((prevState) => ({
        ...prevState,
        popupOpen: false,
        popupId: null,
        navigationOpen: true,
    }));

    const closeNavigation = () => setState((prevState) => ({
        ...prevState,
        navigationOpen: false
    }));

    const toggleNavigation = () => setState((prevState) => ({
        ...prevState,
        navigationOpen: !prevState.navigationOpen
    }));

    const setPopupData = (popupData) => setState({
        popupData
    });

    const setPageTheme = (pageTheme) => setState((prevState) => ({
        ...prevState,
        pageTheme
    }));

    const togglePopup = (id) => setState((prevState) => ({
        ...prevState,
        popupOpen: !prevState.popupOpen,
        popupId: id
    }));

    return (
        <AppContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                ...state,
                openNavigation,
                closeNavigation,
                toggleNavigation,
                setPopupData,
                setPageTheme,
                togglePopup,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;

export const withAppContext = (Component) => (props) => (
    <AppContext.Consumer>
        {(context) => <Component {...props} appContext={context} />}
    </AppContext.Consumer>
);
